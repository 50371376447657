<template>
  <section class="container">
    <form>
      <b-field label="Name">
        <b-input type="text" placeholder="Name" required></b-input>
      </b-field>
      <b-field label="Email">
        <b-input
          type="email"
          placeholder="address@domain.tld"
          required
        ></b-input>
      </b-field>
      <b-field label="Message">
        <b-input
          type="textarea"
          placeholder="Your message here."
          required
        ></b-input>
      </b-field>
      <div class="buttons">
        <b-button type="is-primary">Submit</b-button>
        <b-button type="is-primary is-light">Cancel</b-button>
      </div>
    </form>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
