import { createRouter, createWebHistory } from "vue-router";

//Pages
import About from "../views/About.vue";
import Home from "../views/Home";
import Portfolio from "../views/Portfolio.vue";

//Testing Components
import Contact from "./../components/dialogs/Contact.vue";

//404
import PageNotFound from "../views/PageNotFound.vue";

const routes = [
  //Views
  { path: "/", name: "Home", component: Home },
  { path: "/about", name: "About", component: About },
  { path: "/portfolio", name: "Portfolio", component: Portfolio },

  //Testing Components
  {path: "/contact", name: "Contact", component: Contact },

  //404
  { path: "/:pathMatch(.*)*", component: PageNotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active'
});

export default router;
