<template>
    <div class="container">
        <Hero />
    </div>
</template>

<script>
import Hero from "../components/homeComponents/Hero"

export default {
    name: "Home",
  components: {
      Hero,
  },
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>

<style scoped>

</style>