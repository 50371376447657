<template>
  <footer>
      &copy; Shingai Zindi | 2021
  </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>