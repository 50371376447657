<template>
    <h1>About</h1>
</template>

<script>
export default {
    name: "About",
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>

<style scoped>

</style>