<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <router-link class="navbar-brand" :to="{ path: '/' }">
        <img class="navbar-logo" src="@/assets/logo.png" alt="Home" height="40" width="40"/>
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav">
          <li class="navbar-item">
            <router-link class="nav-link" :active="active" :to="{ path: '/'}">Home</router-link>
          </li>
          <li class="navbar-item">
            <router-link class="nav-link" :active="active" :to="{ path: '/about'}">About</router-link>
          </li>
          <li class="navbar-item">
            <router-link class="nav-link" :active="active" :to="{ path: '/portfolio'}">Portfolio</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>

</style>